import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import { useUser } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { PageContainer } from "pages/components/PageStyles";
import { useEffect } from "react";
import styled from "styled-components";
import { BuiltRoutePath } from "./routes";

const StyledPageContentBody = styled(Column)`
  align-items: center;
`;

const TemplatesLibraryPage = () => {
  const user = useUser();
  const { navigateTo } = useAppRoutes();

  const ajSub = user.findAJActiveSubscription();

  const openTemplatesLibrary = () => {
    window.open(
      `${process.env.REACT_APP_LEGALSTART_CMS_URL}/assistance-legalstart/bibliotheque-documents-juridiques/`,
      "_blank"
    );
  };

  useEffect(() => {
    if (ajSub) {
      openTemplatesLibrary();
    } else {
      navigateTo(BuiltRoutePath.TemplatesLibraryUpsellPage);
    }
  }, [ajSub, navigateTo]);

  return (
    <PageContainer>
      <StyledPageContentBody>
        <Title type="H2">Modèles de documents</Title>
        <Text fontWeightVariant="bold">
          Nous vous avons redirigé vers la liste des modèles de documents.
        </Text>
        <Button onClick={openTemplatesLibrary} size="small">
          Rouvrir la liste
        </Button>
      </StyledPageContentBody>
    </PageContainer>
  );
};

export default TemplatesLibraryPage;
