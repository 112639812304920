import { useQuery } from "@tanstack/react-query";
import { Button, Column, Spinner, Text, Title } from "@yolaw/ui-kit-components";
import { CheckmarkFlat } from "@yolaw/ui-kit-icons";
import { FormalitiesContext } from "contexts";
import { ModalName } from "contexts/app";
import { useApp, useUser } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigService, LegacySubscriptionService } from "services";
import styled, { css, useTheme } from "styled-components";
import { FormalitySourceType } from "types/formalities";
import LegacySubscription, {
  LegacySubscriptionSlug,
} from "types/legacy-subscriptions";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import { AJSubscriptionPaymentModal } from "./AJSubscriptionPaymentModal";
import { BuiltRoutePath, JURIDIQUE_QUESTION_REDIRECTION_URL } from "./routes";

const PlanItemContainer = styled(Column)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.m}px;
    padding: ${theme.spacing.xs}px;
    box-shadow: ${theme.shadows.blue.small};
    max-width: 300px;
    text-align: center;

    sup {
      font-size: 0.8rem;
    }
  `}
`;

const planBenefits = [
  "Rendez-vous illimités",
  "Téléchargez les modèles dont vous avez besoin (contrat, attestation,...)",
];

const BenefitItemContainer = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing.xxxs}px;
  text-align: left;
  svg {
    flex-shrink: 0;
    margin-top: 4px;
  }
`;
const BenefitItem = ({ benefit }: { benefit: string }) => {
  const theme = useTheme();

  return (
    <BenefitItemContainer>
      <CheckmarkFlat size="16" color={theme.colors.primary.main} />
      <Text type="body">{benefit}</Text>
    </BenefitItemContainer>
  );
};

const BenefitList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.xxxs}px;
`;

const LegacySubscriptionPlanItem = ({
  plan,
}: {
  plan: LegacySubscription.Plan | undefined;
}) => {
  const app = useApp();
  const {
    state: { formalities },
  } = useContext(FormalitiesContext.Context);

  const hasFunnel = formalities?.some(
    (formality) =>
      formality.source_type === FormalitySourceType.Funnel ||
      formality.source_type === FormalitySourceType.Service
  );

  const openPaymentModal = () => {
    app.openModal({
      name: ModalName.AJSubscriptionPayment,
      openedBy: {
        context: "userspace assistance",
        from: "aj upsell page",
      },
    });
  };

  const handlePlanSelect = async () => {
    if (hasFunnel) {
      // user has funnel => open payment modal
      openPaymentModal();
    } else {
      // no funnel at all => start LCFunnel for AJ
      let lafoyBaseUrl = "https://forms.legalstart.fr";

      // Try to get LAFOY_BASE_URL from core-api if not in production
      if (process.env.REACT_APP_ENVIRONMENT_NAME !== "production") {
        const linkedAppUrls = await ConfigService.getCoreTestEnvLinkedAppUrls();
        if (!linkedAppUrls.LAFOY_BASE_URL) {
          throw new Error("LAFOY_BASE_URL is not set");
        }
        lafoyBaseUrl = linkedAppUrls.LAFOY_BASE_URL;
      }

      window.open(`${lafoyBaseUrl}/assistance-legalstart`);
    }
  };

  if (!plan) {
    return <Spinner />;
  }

  return (
    <PlanItemContainer>
      <div>
        <Title type="H4">
          {NumberUtils.currencyFormat(plan?.price_et || 0)}&nbsp;<sup>HT</sup>
          &nbsp;/
          {TranslatorUtils.translate(plan?.period_unit || "")}
        </Title>
        <Text type="small" fontWeightVariant="bold">
          sans engagement
        </Text>
      </div>

      <BenefitList>
        {planBenefits.map((benefit) => (
          <li key={benefit}>
            <BenefitItem benefit={benefit} />
          </li>
        ))}
      </BenefitList>

      <Button size="small" onClick={handlePlanSelect}>
        Choisir
      </Button>
    </PlanItemContainer>
  );
};

const StyledTitle = styled(Title).attrs({
  type: "H2",
})`
  text-align: center;
`;
const PageContentContainer = styled(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.l}px;
    align-items: center;
    max-width: 720px;
    margin: 0 auto;
  `}
`;

export enum AJUpsellSource {
  JuridiqueQuestion = "juridique_question",
  TemplatesLibrary = "templates_library",
}

type Props = {
  source: AJUpsellSource;
};

const PLAN_SLUG_TO_SELL = "cross-sell";

const AJUpsellPage = ({ source }: Props) => {
  const app = useApp();
  const user = useUser();
  const ajSub = user.findAJActiveSubscription();
  const navigate = useNavigate();

  const [subscriptionPlan, setSubscriptionPlan] = useState<
    LegacySubscription.Plan | undefined
  >(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const subDetailsQuery = useQuery({
    queryKey: [
      "/subscriptions/types/",
      LegacySubscriptionSlug.AssistanceJuridique,
    ],
    queryFn: () =>
      LegacySubscriptionService.getSubscriptionDetails(
        LegacySubscriptionSlug.AssistanceJuridique
      ),
    staleTime: 1000 * 60,
    refetchOnMount: false,
  });

  const { data: subDetails } = subDetailsQuery;

  const getSubscriptionPlan = useCallback(() => {
    const planToSell = subDetails?.plans.find(
      (plan) => plan.slug === PLAN_SLUG_TO_SELL
    );

    if (!planToSell) {
      setError(new Error("Abonnement à vendre non trouvé."));
      return;
    }

    setSubscriptionPlan(planToSell);
  }, [subDetails]);

  useEffect(() => {
    if (subDetails) {
      getSubscriptionPlan();
    }
  }, [getSubscriptionPlan, subDetails]);

  useEffect(() => {
    if (ajSub) {
      if (source === AJUpsellSource.JuridiqueQuestion) {
        window.location.assign(JURIDIQUE_QUESTION_REDIRECTION_URL);
      } else {
        navigate(BuiltRoutePath.TemplatesLibraryPage, { replace: true });
      }
    }
  }, [ajSub, navigate, source]);

  const titlePrefix =
    source === AJUpsellSource.TemplatesLibrary
      ? "Débloquez les modèles de documents"
      : "Posez vos questions juridiques";

  return (
    <>
      <PageContainer>
        <PageContentContainer>
          <StyledTitle>{titlePrefix} avec l’Assistance Legalstart</StyledTitle>

          {error ? (
            <Text color="error.dark" fontWeightVariant="bold">
              {error.message}
            </Text>
          ) : (
            <LegacySubscriptionPlanItem plan={subscriptionPlan} />
          )}
        </PageContentContainer>
      </PageContainer>
      {app.openingModal?.name === ModalName.AJSubscriptionPayment && (
        <AJSubscriptionPaymentModal planSlug={PLAN_SLUG_TO_SELL} />
      )}
    </>
  );
};

export default AJUpsellPage;
