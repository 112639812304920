import { Elements } from "@stripe/react-stripe-js";
import { Column } from "@yolaw/ui-kit-components";
import { useEffect, useReducer, useRef } from "react";
import styled, { css } from "styled-components";

import Modal, { CustomHTMLDialogElement } from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp, usePrevious, useSegment } from "hooks";
import StripeProvider from "providers/stripe";
import { LegacySubscriptionService } from "services";
import LegacySubscription from "types/legacy-subscriptions";
import PaymentModalContext, {
  PaymentModalContextAction,
  PaymentModalContextState,
  PaymentModalStep,
} from "./context";
import { IntroductionPage, PaymentPage, PlanSelectionPage } from "./pages";
import { IntroductionPageProps } from "./pages/Introduction";
import { PlanSelectionProps } from "./pages/PlanSelection";

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    .modal-container-inner {
      @media (min-width: ${theme.breakpoints.l}px) {
        max-width: ${theme.breakpoints.l}px;
      }
    }
  `}
`;

const BodyContainer = styled<React.ElementType>(Column)`
  align-items: center;
  text-align: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.s}px;
  `}

  sup {
    font-size: 0.5em;
  }
`;

const getStepNameForTrackingEvent = (step: PaymentModalStep) => {
  switch (step) {
    case PaymentModalStep.Introduction:
      return "benefits";
    case PaymentModalStep.PlanSelection:
      return "plans";
    case PaymentModalStep.Payment:
      return "form";
  }
};

type StepsConfigs = {
  [PaymentModalStep.Introduction]: IntroductionPageProps;
  [PaymentModalStep.PlanSelection]: PlanSelectionProps;
};

type SubscriptionPaymentModalProps = {
  modalName: ModalName;
  modalConfigs: PaymentModalContextState["modalConfigs"];
  subscriptionTypeSlug: string;
  planSlug?: LegacySubscription.Plan["slug"];
} & (
  | {
      stepsConfigs: StepsConfigs;
      onlyPaymentStep?: never;
    }
  | {
      stepsConfigs?: never;
      onlyPaymentStep: true;
    }
);

/** This component handles the payment process for legacy subscriptions. */
const LegacySubscriptionPaymentModal = ({
  modalName,
  modalConfigs,
  planSlug,
  subscriptionTypeSlug,
  stepsConfigs,
  onlyPaymentStep,
}: SubscriptionPaymentModalProps) => {
  const app = useApp();
  const segment = useSegment();
  const prevOpeningModal = usePrevious(app.openingModal);
  const modalRef = useRef<CustomHTMLDialogElement | null>(null);

  const [state, dispatch] = useReducer(PaymentModalContext.reducer, {
    ...PaymentModalContext.initialState,
    selectedPlanSlug: planSlug ?? null,
    currentModalStep: onlyPaymentStep
      ? PaymentModalStep.Payment
      : PaymentModalContext.initialState.currentModalStep,
  });
  const prevStep =
    usePrevious(state.currentModalStep) || state.currentModalStep;

  useEffect(() => {
    dispatch({
      type: PaymentModalContextAction.SetModalRef,
      payload: modalRef,
    });
  }, [modalRef]);

  const getSubscriptionDetails = async () => {
    const subscription = await LegacySubscriptionService.getSubscriptionDetails(
      subscriptionTypeSlug
    );
    dispatch({
      type: PaymentModalContextAction.SetSubscriptionDetails,
      payload: subscription,
    });
  };

  useEffect(() => {
    // Whenever the modal is opened
    if (app.openingModal?.name === modalName) {
      if (!state.subscription) {
        // Get subscription info if it's not there
        getSubscriptionDetails();
      }
    }

    // When `modalName` modal is closed, reset its step to `PaymentModalStep.Introduction`
    if (prevOpeningModal?.name === modalName && !app.openingModal) {
      dispatch({
        type: PaymentModalContextAction.SetPaymentModalStep,
        payload: PaymentModalStep.Introduction,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.openingModal]);

  useEffect(() => {
    if (app.openingModal?.name === modalName) {
      // Only check if the modal is currently opened
      const comeFrom =
        state.currentModalStep === prevStep
          ? app.openingModal.openedBy?.from // modal just opened
          : getStepNameForTrackingEvent(prevStep); // jumping between steps

      segment.track("paywall: displayed", {
        context: app.openingModal.openedBy?.context,
        from: comeFrom,
        "subscription product": subscriptionTypeSlug,
        step: getStepNameForTrackingEvent(state.currentModalStep),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentModalStep, app.openingModal]);

  useEffect(() => {
    dispatch({
      type: PaymentModalContextAction.SetModalConfigs,
      payload: modalConfigs,
    });
  }, [modalConfigs]);

  const onClickBackHandler = () => {
    if (onlyPaymentStep) {
      return undefined;
    }

    switch (state.currentModalStep) {
      case PaymentModalStep.PlanSelection:
        return () =>
          dispatch({
            type: PaymentModalContextAction.SetPaymentModalStep,
            payload: PaymentModalStep.Introduction,
          });
      case PaymentModalStep.Payment:
        return () =>
          dispatch({
            type: PaymentModalContextAction.SetPaymentModalStep,
            payload: PaymentModalStep.PlanSelection,
          });
      case PaymentModalStep.Introduction:
      default:
        return undefined;
    }
  };

  const renderPageContent = () => {
    if (!stepsConfigs || onlyPaymentStep) {
      return <PaymentPage />;
    }

    switch (state.currentModalStep) {
      case PaymentModalStep.Introduction:
        return (
          <IntroductionPage {...stepsConfigs[PaymentModalStep.Introduction]} />
        );
      case PaymentModalStep.PlanSelection:
        return (
          <PlanSelectionPage
            {...stepsConfigs[PaymentModalStep.PlanSelection]}
          />
        );
      case PaymentModalStep.Payment:
      default:
        return <PaymentPage />;
    }
  };

  return (
    <Elements stripe={StripeProvider.stripePromise}>
      <PaymentModalContext.Context.Provider value={{ state, dispatch }}>
        <StyledModal
          isOpen={app.openingModal?.name === modalName}
          onClose={app.closeModal}
          onClickBack={onClickBackHandler()}
          ref={modalRef}
        >
          <BodyContainer>{renderPageContent()}</BodyContainer>
        </StyledModal>
      </PaymentModalContext.Context.Provider>
    </Elements>
  );
};

export default LegacySubscriptionPaymentModal;
