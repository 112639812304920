import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import { PageContainer } from "pages/components/PageStyles";
import { useEffect } from "react";
import styled from "styled-components";

const StyledPageContentBody = styled(Column)`
  align-items: center;
`;

const SupportPage = () => {
  const openFAQ = () => {
    window.open("https://help.legalstart.fr/fr/", "_blank");
  };

  useEffect(() => {
    openFAQ();
  }, []);

  return (
    <PageContainer>
      <StyledPageContentBody>
        <Title type="H2">Questions fréquentes</Title>
        <Text fontWeightVariant="bold">
          Nous vous avons redirigé vers la FAQ.
        </Text>
        <Button onClick={openFAQ} size="small">
          Rouvrir la FAQ
        </Button>
      </StyledPageContentBody>
    </PageContainer>
  );
};

export default SupportPage;
