import coreApiInstance from "../coreApi";

export const getConfigReferenceCountries = async () =>
  coreApiInstance.get<CountryReferenceInterface[]>(
    "/config/reference/countries/"
  );

interface LinkedAppURLs {
  BILLING_URL: string;
  LAFOY_BASE_URL: string;
  LEGALZEN_API_URL: string;
  SERVICE_FORM_URL: string;
  WEB_APP_DOMAIN: string;
  USERSPACE_BASE_URL: string;
}
export const getCoreTestEnvLinkedAppUrls = async () =>
  coreApiInstance.get<LinkedAppURLs>("/env_var_configuration/");
