import { Spinner, Text } from "@yolaw/ui-kit-components";
import { useApp, useLegalEntity, useUser } from "hooks";
import { useEffect, useState } from "react";
import { WebSubscriptionsService } from "services";
import styled, { css } from "styled-components";
import LegacySubscription, {
  LegacySubscriptionStatus,
} from "types/legacy-subscriptions";
import Subscription, { SubscriptionStatus } from "types/subscriptions";
import LegacySubscriptionItem, {
  TLegacySubscriptionWithWebInfo,
} from "./LegacySubscriptionItem";
import SubscriptionItem from "./SubscriptionItem";

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  list-style: none;

  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      row-gap: ${theme.spacing.l}px;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      row-gap: ${theme.spacing.s}px;
    }
  `}

  .subs_status * {
    font-weight: bold;
  }
  .subs_status.hidden {
    display: none;
  }
`;

type SubscriptionsProps = {
  subscriptions: Array<Subscription>;
};
const Subscriptions = ({ subscriptions }: SubscriptionsProps) => {
  return (
    <>
      {subscriptions.map((sub) => (
        <li key={sub.id} className="subscription-item">
          <SubscriptionItem subscription={sub} />
        </li>
      ))}
    </>
  );
};

type LegacySubscriptionsProps = {
  legacySubscriptions: Array<LegacySubscription.SubscriptionItem>;
};
const LegacySubscriptions = ({
  legacySubscriptions,
}: LegacySubscriptionsProps) => {
  const app = useApp();

  const [mappedSubscriptions, setMappedSubscriptions] = useState<
    TLegacySubscriptionWithWebInfo[]
  >([]);

  const _getWebSubscriptions = async () => {
    const webSubscriptions =
      await WebSubscriptionsService.getWebSubscriptions();

    // Store in app context
    app.setWebSubscriptions(webSubscriptions);
  };

  useEffect(() => {
    if (!app.webSubscriptions.length) {
      _getWebSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (legacySubscriptions.length && app.webSubscriptions.length) {
      const _mappedSubs: TLegacySubscriptionWithWebInfo[] =
        legacySubscriptions.map((sub) => ({
          ...sub,
          webInfo: app.webSubscriptions.find(
            (webSub) => webSub.name === sub.plan.group
          ),
        }));

      setMappedSubscriptions(_mappedSubs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacySubscriptions?.length, app.webSubscriptions?.length]);

  if (legacySubscriptions?.length && !app.webSubscriptions.length) {
    return <Spinner />;
  }

  if (!mappedSubscriptions.length) return null;

  return (
    <>
      {/* Legacy User's subs: TODO: if it's a case */}
      {/* Legacy LE's subs */}
      {mappedSubscriptions.map((sub) => (
        <li key={sub.id} className="legacy-subscription-item">
          <LegacySubscriptionItem subscription={sub} />
        </li>
      ))}
    </>
  );
};

/** @see https://legalstart.slack.com/archives/C06QDSTT8TS/p1732601470691249 */
const LEGACY_SUBSCRIPTION_MANAGEABLE_STATUSES: LegacySubscriptionStatus[] = [
  LegacySubscriptionStatus.Active,
  LegacySubscriptionStatus.PastDue,
  LegacySubscriptionStatus.PausedCollection,
  LegacySubscriptionStatus.Trialing,
];

/** @see https://legalstart.slack.com/archives/C06QDSTT8TS/p1732601470691249 */
const SUBSCRIPTION_MANAGEABLE_STATUSES: SubscriptionStatus[] = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Draft,
  SubscriptionStatus.PastDue,
  SubscriptionStatus.RenewalFailure,
  SubscriptionStatus.Trialing,
];

export const SubscriptionList = () => {
  const legalEntity = useLegalEntity();
  const user = useUser();

  // Filters the manageable subscriptions by status
  const subscriptionsManageable = legalEntity.subscriptions.filter((s) =>
    SUBSCRIPTION_MANAGEABLE_STATUSES.includes(s.status)
  );

  const legacySubscriptions = [...legalEntity.legacySubscriptions, ...user.legacySubscriptions];
  const legacySubscriptionsManageable = legacySubscriptions.filter(
    (s) => LEGACY_SUBSCRIPTION_MANAGEABLE_STATUSES.includes(s.status)
  );

  const hasSubscription =
    subscriptionsManageable.length || legacySubscriptionsManageable.length;

  const toggleStatus = (event: KeyboardEvent) => {
    if (event.key === "?") {
      const elements = document.querySelectorAll(".subs_status");
      elements.forEach((element) => {
        element.classList.toggle("hidden");
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", toggleStatus);
    return () => {
      document.removeEventListener("keyup", toggleStatus);
    };
  }, []);

  return (
    <Container>
      {hasSubscription ? (
        <>
          <Subscriptions subscriptions={subscriptionsManageable} />
          <LegacySubscriptions
            legacySubscriptions={legacySubscriptionsManageable}
          />
        </>
      ) : (
        <Text fontWeightVariant="bold">
          Vous n&apos;avez souscrit à aucun abonnement.
        </Text>
      )}
    </Container>
  );
};
