import { ColorPropValue } from "@yolaw/ui-kit-components/types/ui-kit-components/src/colors/types";
import { TagColor } from "@yolaw/ui-kit-components/types/ui-kit-components/src/components/Tag";
import { CustomHTMLDialogElement } from "components/modal/Modal";
import React from "react";
import LegacySubscription from "types/legacy-subscriptions";

export enum PaymentModalStep {
  Introduction = "INTRODUCTION",
  PlanSelection = "PLAN_SELECTION",
  Payment = "PAYMENT",
}

export type PaymentModalContextState = {
  currentModalStep: PaymentModalStep;
  modalConfigs: {
    subscriptionDisplayName: string;
    styles: {
      color: ColorPropValue;
      tagColor: TagColor;
    };
    paymentPageTitle?: string | React.ReactNode;
    paymentButtonLabel?: string;
    successMessageTitle?: string;
  };
  selectedPlanSlug: LegacySubscription.Plan["slug"] | null;
  subscription: LegacySubscription.SubscriptionDetails | null;
  modalRef: React.MutableRefObject<CustomHTMLDialogElement | null> | null;
};

const initialState: PaymentModalContextState = {
  currentModalStep: PaymentModalStep.Introduction,
  modalConfigs: {
    subscriptionDisplayName: "MISSING_SUBSCRIPTION_DISPLAY_NAME",
    styles: {
      color: "error.dark",
      tagColor: "red",
    },
  },
  selectedPlanSlug: null,
  subscription: null,
  modalRef: null,
};

export enum PaymentModalContextAction {
  SetModalConfigs = "SET_MODAL_CONFIGS",
  SetPaymentModalStep = "SET_PAYMENT_MODAL_STEP",
  SetSelectedPlanSlug = "SET_SELECTED_PLAN_SLUG",
  SetSubscriptionDetails = "SET_SUBSCRIPTION_DETAILS",
  SetModalRef = "SET_MODAL_REF",
}

type SetModalConfigsAction = {
  type: PaymentModalContextAction.SetModalConfigs;
  payload: PaymentModalContextState["modalConfigs"];
};

type SetPaymentModalStepAction = {
  type: PaymentModalContextAction.SetPaymentModalStep;
  payload: PaymentModalContextState["currentModalStep"];
};

type SetSelectedPlanSlugAction = {
  type: PaymentModalContextAction.SetSelectedPlanSlug;
  payload: PaymentModalContextState["selectedPlanSlug"];
};

type SetSubscriptionDetailsAction = {
  type: PaymentModalContextAction.SetSubscriptionDetails;
  payload: PaymentModalContextState["subscription"];
};

type SetModalRefAction = {
  type: PaymentModalContextAction.SetModalRef;
  payload: PaymentModalContextState["modalRef"];
};

type PaymentModalDispatchAction =
  | SetModalConfigsAction
  | SetPaymentModalStepAction
  | SetSelectedPlanSlugAction
  | SetSubscriptionDetailsAction
  | SetModalRefAction;

const reducer = (
  state: PaymentModalContextState,
  action: PaymentModalDispatchAction
): PaymentModalContextState => {
  switch (action.type) {
    case PaymentModalContextAction.SetModalConfigs:
      return {
        ...state,
        modalConfigs: {
          ...state.modalConfigs,
          ...action.payload,
        },
      };
    case PaymentModalContextAction.SetPaymentModalStep:
      return {
        ...state,
        currentModalStep: action.payload,
      };
    case PaymentModalContextAction.SetSelectedPlanSlug:
      return {
        ...state,
        selectedPlanSlug: action.payload,
      };
    case PaymentModalContextAction.SetSubscriptionDetails:
      return {
        ...state,
        subscription: action.payload,
      };
    case PaymentModalContextAction.SetModalRef:
      return {
        ...state,
        modalRef: action.payload,
      };
    default:
      throw new Error("[PaymentModalContext] unexpected action type");
  }
};

type PaymentModalContextType = {
  state: PaymentModalContextState;
  dispatch: React.Dispatch<PaymentModalDispatchAction>;
};

const Context = React.createContext<PaymentModalContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "PaymentModal";

const PaymentModalContext = {
  Context,
  initialState,
  reducer,
};

export default PaymentModalContext;
