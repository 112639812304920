import { Navigate, RouteObject } from "react-router-dom";
import AJUpsellPage, { AJUpsellSource } from "./AJUpsellPage";
import SupportPage from "./SupportPage";
import TemplatesLibraryPage from "./TemplatesLibraryPage";

export const RoutePath = {
  SupportPage: "support",
  TemplatesLibraryPage: "templates-library",
  JuridiqueQuestionPage: "assistance-juridique",
  UpsellPage: "upsell",
};

export const BuiltRoutePath = {
  JuridiqueQuestionUpsellPage: `/${RoutePath.JuridiqueQuestionPage}/${RoutePath.UpsellPage}`,
  SupportPage: `/${RoutePath.SupportPage}`,
  TemplatesLibraryPage: `/${RoutePath.TemplatesLibraryPage}`,
  TemplatesLibraryUpsellPage: `/${RoutePath.TemplatesLibraryPage}/${RoutePath.UpsellPage}`,
};

export const JURIDIQUE_QUESTION_REDIRECTION_URL = `${process.env.REACT_APP_WEB_APP_DOMAIN}/compte/rendezvous-juriste/assistance-juridique/`;

const appRoutes: RouteObject[] = [
  {
    path: RoutePath.SupportPage,
    element: <SupportPage />,
  },
  {
    path: `${RoutePath.JuridiqueQuestionPage}/*`,
    children: [
      {
        index: true,
        element: <Navigate to={RoutePath.UpsellPage} />,
      },
      {
        path: RoutePath.UpsellPage,
        element: <AJUpsellPage source={AJUpsellSource.JuridiqueQuestion} />,
      },
    ],
  },
  {
    path: `${RoutePath.TemplatesLibraryPage}/*`,
    children: [
      {
        index: true,
        element: <TemplatesLibraryPage />,
      },
      {
        path: RoutePath.UpsellPage,
        element: <AJUpsellPage source={AJUpsellSource.TemplatesLibrary} />,
      },
    ],
  },
];

export default appRoutes;
