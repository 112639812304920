import { Accent } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import LegacySubscription, {
  LegacySubscriptionSlug,
} from "types/legacy-subscriptions";
import LegacySubscriptionPaymentModal from "../../../components/Modals/LegacySubscriptionPayment";

const SUBSCRIPTION_DISPLAY_NAME = "Assistance Legalstart";

type Props = {
  planSlug: LegacySubscription.Plan["slug"];
};

export const AJSubscriptionPaymentModal = ({ planSlug }: Props) => {
  return (
    <LegacySubscriptionPaymentModal
      modalName={ModalName.AJSubscriptionPayment}
      subscriptionTypeSlug={LegacySubscriptionSlug.AssistanceJuridique}
      modalConfigs={{
        subscriptionDisplayName: SUBSCRIPTION_DISPLAY_NAME,
        styles: {
          color: "primary.main",
          tagColor: "green",
        },
        paymentPageTitle: (
          <>
            M&apos;abonner à l&apos;
            <Accent color={"primary.main"}>{SUBSCRIPTION_DISPLAY_NAME}</Accent>
          </>
        ),
        paymentButtonLabel: `M'abonner à l'${SUBSCRIPTION_DISPLAY_NAME}`,
        successMessageTitle: `Vous êtes bien abonné à l'${SUBSCRIPTION_DISPLAY_NAME} !`,
      }}
      planSlug={planSlug}
      onlyPaymentStep
    />
  );
};
