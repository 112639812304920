import ApiService from "services/api";

export const decodeConfigReferenceCountry = (
  payload: CountryReferenceInterface
): CountryReferenceType => {
  return {
    nameFr: payload.name_fr,
    intlDialCode: payload.intl_dial_code,
    countryIso2Code: payload.country_iso2_code,
    flagEmoji: payload.flag_emoji,
  };
};

const getCountryReferences = async () => {
  try {
    const response = await ApiService.configs.getConfigReferenceCountries();

    const configReferenceCountries = (response?.data || []).map(
      (item: CountryReferenceInterface) => decodeConfigReferenceCountry(item)
    );
    return configReferenceCountries;
  } catch (error) {
    throw error;
  }
};

const getCoreTestEnvLinkedAppUrls = async () => {
  try {
    const response = await ApiService.configs.getCoreTestEnvLinkedAppUrls();
    return response.data;
  } catch (error) {
    throw error;
  }
};

const ConfigService = {
  getCountryReferences,
  getCoreTestEnvLinkedAppUrls,
};

export default ConfigService;
