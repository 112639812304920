import GooglePayButtonReact from "@google-pay/button-react";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import useLegacySubscriptionPayment from "hooks/useLegacySubscriptionPayment";
import GooglePayProvider from "providers/googlePay";
import { useEffect } from "react";
import { usePaymentModalContext } from "../../../hooks";

type GooglePayButtonProps = {
  disabled?: boolean;
};
const GooglePayButton = ({ disabled }: GooglePayButtonProps) => {
  const paymentForm = usePaymentFormContext();
  const paymentModal = usePaymentModalContext();
  const lsPayment = useLegacySubscriptionPayment({
    plan: paymentModal.selectedPlan,
    subscriptionDisplayName:
      paymentModal.state.modalConfigs.subscriptionDisplayName,
    successMessageTitle: paymentModal.state.modalConfigs.successMessageTitle,
  });

  const amountToPay = paymentModal.selectedPlan?.price_it || 0;

  useEffect(() => {
    paymentForm.action.setPaymentError(lsPayment.paymentError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lsPayment.paymentError]);

  const handleButtonClick = (event: Event) => {
    // Try to validate payment information
    if (disabled) {
      // If the validation is not pass
      // => prevent the Google payment sheets to be opened
      event.preventDefault();
    }
  };

  const onPaymentAuthorized = (
    paymentData: google.payments.api.PaymentData
  ):
    | Promise<google.payments.api.PaymentAuthorizationResult>
    | google.payments.api.PaymentAuthorizationResult => {
    const paymentTokenId = JSON.parse(
      paymentData.paymentMethodData.tokenizationData.token
    ).id;

    if (paymentTokenId) {
      lsPayment.payWithGooglePay(paymentTokenId);

      // close Google Pay payment sheet
      return {
        transactionState: "SUCCESS",
      };
    }

    // close Google Pay payment sheet
    return {
      transactionState: "ERROR",
    };
  };

  return (
    <GooglePayButtonReact
      buttonLocale="fr"
      buttonType={"pay"}
      environment={
        process.env.REACT_APP_ENVIRONMENT_NAME === "production"
          ? "PRODUCTION"
          : "TEST"
      }
      paymentRequest={GooglePayProvider.getGooglePaymentDataRequest(
        amountToPay.toString()
      )}
      onClick={handleButtonClick}
      onPaymentAuthorized={onPaymentAuthorized}
      buttonSizeMode="fill"
      style={{ height: "50px", width: "100%" }}
    />
  );
};

export default GooglePayButton;
