import { Button } from "@yolaw/ui-kit-components";
import React, { useEffect } from "react";

import { usePaymentModalContext } from "components/Modals/LegacySubscriptionPayment/hooks";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import useLegacySubscriptionPayment from "hooks/useLegacySubscriptionPayment";
import { PaymentMethod, PaymentOption } from "services/payment";
import ApplePayButton from "./ApplePay";
import GooglePayButton from "./GooglePay";

const PayButton = () => {
  const paymentForm = usePaymentFormContext();
  const { isReadyToPay, paymentMethod, paymentOption, selectedCard } =
    paymentForm.state;

  const paymentModal = usePaymentModalContext();
  const { selectedPlan } = paymentModal;
  const { modalConfigs, modalRef } = paymentModal.state;
  const { subscriptionDisplayName, paymentButtonLabel } = modalConfigs;

  const lsPayment = useLegacySubscriptionPayment({
    plan: selectedPlan,
    subscriptionDisplayName,
    paymentModalRef: modalRef,
    successMessageTitle: modalConfigs.successMessageTitle,
  });

  useEffect(() => {
    paymentForm.action.setPaymentError(lsPayment.paymentError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lsPayment.paymentError]);

  const handleCardPayment = () => {
    lsPayment.payWithCard(selectedCard?.payment_method_id);
  };

  const shouldDisableButton =
    !selectedPlan?.price_it || !isReadyToPay || lsPayment.isProcessingPayment;

  if (
    paymentOption === PaymentOption.UseSavedCard ||
    paymentMethod === PaymentMethod.Card
  ) {
    return (
      <Button
        disabled={shouldDisableButton}
        isLoading={lsPayment.isProcessingPayment}
        onClick={handleCardPayment}
      >
        {paymentButtonLabel || `M’abonner à ${subscriptionDisplayName}`}
      </Button>
    );
  }

  if (paymentMethod === PaymentMethod.ApplePay) {
    return <ApplePayButton disabled={shouldDisableButton} />;
  }

  if (paymentMethod === PaymentMethod.GooglePay) {
    return <GooglePayButton disabled={shouldDisableButton} />;
  }

  return <></>;
};

export default React.memo(PayButton);
